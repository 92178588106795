

.slidebar-box {

/*	color: red;*/
	width: 100%;
/*	background: salmon;*/
	height: 2em;
/*	padding: .5em 0;*/
	display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.slidebar-button-box {
/*	background: black;*/
	display: table-row;
}

.slidebar-button {
	user-select: none;
	cursor: pointer;
	font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
	margin: 0 .5em;
	background: var(--window-background);
/*	border-radius: var(--border-radius);*/
    box-shadow: 0 0 0 1px var(--window-border-color), 0 0 5em #00000077;
    font-family: monospace;
    padding: .4em .2em;
    transition: .2s ease-in-out;

	white-space: nowrap;
    max-width: 10em;
    min-width: fit-content;
}

.slidebar-button:hover {
    box-shadow: 0 0 0 4px var(--window-border-color), 0 0 5em #00000077;
}

.slidebar-button.selected {
	cursor: auto;
	background: var(--window-border-color);
}

.slidebar-button.selected:hover {
    box-shadow: 0 0 0 1px var(--window-border-color), 0 0 5em #00000077;
}

/*.slidebar {
	position: absolute;
	border-radius: 1em;
	color: black;
	background: skyblue;
	width: 100%;
	height: .5em;
}

.slidebar-box > .slider {
	cursor: pointer;
	position: absolute;
	border-radius: 100%;
	background: red;
	height: 1em;
	width: 1em;
	transform: translate(-150%);
	transition: .1s ease-out;
}*/