

.banner-body {
	position: fixed;
	z-index: 999;
	right: 0;
	max-width: calc(100vw - 13em);
	width: fit-content;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.banner {
}

.banner.type1 {
	width: 300px;
	height: 250px;
}

.banner.type2 {
	width: 300px;
	height: 300px;
}

.banner.type3 {
	width: 320px;
	height: 50px;
}

.banner.type4 {
	width: 320px;
	height: 100px;
}

.banner.type5 {
	width: 400px;
	height: 240px;
}

.banner.type6 {
	width: 728px;
	height: 90px;
}