
.editorbody {
    font-family: monospace;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    height: 100%;
    background: var(--code-background);
}


.linesnums {
    user-select: none !important;
/*    background: wheat;*/
    display: flex;
    flex-direction: column;
    color: var(--noaccent-foreground);
    height: 100%;
    border-right: 1px solid var(--window-border-color);
    width: 3em;
    padding-top: 1em;
}

.linenum {
    display: block;
    width: calc(3em - var(--window-margin));
    padding-right: .25em;
    text-align: right;
/*    background: red;*/
}

.codebody {
    min-height: 100%;
    outline: none;
    min-width: 1em;
    width: calc(100% - 3em);
    padding-left: 1em;
    padding-top: 1em;
}

.codeline {
    display: block;
    color: var(--code-foreground);
    outline: none;
    width: 100%;
}

