:root {
    --border-radius: 2px;
    --window-margin: .25em;
    --toolbar-height: calc(50px - .5em);

    --background: #0a0a0a;
    --foreground: #ededed;

    --noaccent-foreground: #333;
    --accent-foreground: #96EC3F;

    --window-background: #191919;
    --window-foreground: #ededed;
    --window-border-color: #2e2e2e;

    --content-background: #0a0a0a;

    --code-background: #0a0a0a;
    --code-foreground: #b2b2b2;

/*    --scheme-font: fantasy;*/
    --scheme-font-size: 15px;
    --scheme-font: monospace;
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: #0a0a0a;
        --foreground: #ededed;

        --noaccent-foreground: #333;

        --window-background: #191919;
        --window-foreground: #ededed;
        --window-border-color: #2e2e2e;

        --content-background: #0a0a0a;

        --code-background: #0a0a0a;
        --code-foreground: #b2b2b2;
    }
}

body {
    color: var(--foreground);
    background: var(--background);
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

svg {
    font-family: var(--scheme-font);
    font-size: var(--scheme-font-size);
}

text {
    font-family: var(--scheme-font);
    font-size: var(--scheme-font-size);
}

.scheme-font {
    font-family: var(--scheme-font);
    font-size: var(--scheme-font-size);
}

input {
    outline: none;
    border: none;
    background: var(--content-background);
    color: var(--foreground);
    font-size: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .5em;
    background: var(--window-background);
    box-shadow: 0 0 0 1px var(--window-border-color), 0 0 5em #00000077;
    font-family: monospace;
    padding: .4em 1em;
    transition: .2s ease-in-out;

    white-space: nowrap;
    max-width: 10em;
    min-width: fit-content;
}

.char {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    user-select: none;
}

a {
    color: #0094FF;
    font-weight: bold;
    transition: .2s ease;
}

a:hover {
    color: #75C5FF;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #111;
    box-shadow: inset 6px 0px 6px 0px #000000a1;
}

::-webkit-scrollbar-thumb {
    background: #555;
}

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/